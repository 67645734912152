import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Copyright } from '../../common/Copyright'
import { useHistory } from "react-router-dom"
import { useToggleEditingForm as useForm } from '../../hooks/useForm'
import { FirebaseContext } from '../../components/firebase'
import { LANDING, SIGN_UP } from '../../common/routes'
import golfPic1 from '../../assets/golf1blur.jpg'

const StyledLink = withStyles({
  root: {
    color: 'white',
  },
})(Link);

const StyledTypography = withStyles({
  root: {
    color: 'white',
  },
})(Typography);

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    background: '#FFFFFF',
  },
  input: {
    color: 'black'
  },
  bg: {
    backgroundImage: 'url(' + golfPic1 + ')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory()
  const firebase = useContext(FirebaseContext)
  const { inputs, onChange } = useForm({ initialValues: {} })

  const signIn = () => {
    firebase
      .signInWithEmailAndPassword(inputs.email, inputs.password)
      .then(() => {
        history.push(LANDING)
      })
      .catch((error: any) => {
        alert(error.toString())
      });
  }

  return (
    <div className={classes.bg}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <StyledTypography variant="h5">
            Sign in
        </StyledTypography>
          <form className={classes.form} noValidate>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              className={classes.textField}
              InputProps={{
                className: classes.input,
              }}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onChange}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              className={classes.textField}
              InputProps={{
                className: classes.input,
              }}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              onClick={signIn}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
          </Button>
            <Grid container>
              <Grid item xs>
                <StyledLink href={SIGN_UP} variant="body2">
                  Forgot password?
              </StyledLink>
              </Grid>
              <Grid item>
                <StyledLink href={SIGN_UP} variant="body2">
                  {"Don't have an account? Sign Up"}
                </StyledLink>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}