import React, { useContext, useRef, useEffect, useState, MutableRefObject } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import ThumbUpTwoToneIcon from '@material-ui/icons/ThumbUpTwoTone';
import ThumbDownTwoToneIcon from '@material-ui/icons/ThumbDownTwoTone';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { AuthUserCtxProvider, User } from '../../model/interfaces'
import { FirebaseContext, AuthUserContext } from '../../components/firebase'
import cfg from '../../model/db';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
    },
    heading: {
      fontSize: theme.typography.pxToRem(14),
      marginLeft: '12px',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    column: {
      flexBasis: '10.0%',
    },
    row: {
      flexBasis: '80%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
    },
  }),
);

export default function WhosIn(props: any) {
  const classes = useStyles();
  const [whosIn, setWhosIn] = useState<User[]>([])
  const firebase = useContext(FirebaseContext)
  const auth: AuthUserCtxProvider = useContext(AuthUserContext)
  const inRef: MutableRefObject<any> = useRef()

  const myIsInKey = () => {
    if (whosIn.length === 0) return null;
    let who: User
    for (who of whosIn) {
      if (who.guid === auth.userInfo?.user.guid) {
        return who.id
      }
    }
    return null;
  };

  const imInButtonPressed = () => {
    const myKey = myIsInKey();
    if (myKey !== null) {
      inRef.current.child(myKey).remove();
    } else {
      const item = inRef.current.push();
      item.setWithPriority(
        {
          firstName: auth?.userInfo?.user?.firstName,
          lastName: auth?.userInfo?.user?.lastName,
          initials: auth?.userInfo?.user?.initials,
          guid: auth?.userInfo?.user?.guid,
        },
        firebase.timestamp()
      );
    }
  };

  useEffect(() => {

    const initInOutListener = () => {

      const updateId = (whosIn: [User], key: any) => {
        const w = whosIn[key];
        w.id = key;
        return w;
      };

      return inRef.current.on('value', (snapshot: any) => {
        if (!snapshot || typeof snapshot === 'undefined') return;
        const whosIn: [User] = snapshot.val();
        if (!whosIn || typeof whosIn === 'undefined') {
          setWhosIn([])
        } else {
          const keys = Object.keys(whosIn);
          let inners: User[] = keys.map((key: string) => updateId(whosIn, key))
          setWhosIn(inners)
        }
      });
    };

    let inListener: any
    if (props.event.id !== '') {
      inRef.current = firebase
        .getDatabase()
        .ref(`${cfg.root}/${cfg.inRoot}/`)
        .child(props.event.id);

      inListener = initInOutListener()
    }
    return () => inRef.current ? inRef.current.off('value', inListener) : null
  }, [firebase, props.event.id]);

  const isIn = () => myIsInKey() !== null

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.row}>
            <Badge badgeContent={whosIn.length} color="primary">
              <PersonOutlineTwoToneIcon />
            </Badge>
            <Typography className={classes.heading}>Are you in or out?</Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {whosIn.length > 0 && whosIn.map((who: User) => (
            <div key={who.id} className={classes.column}>
              <Tooltip title={`${who.firstName} ${who.lastName}`}>
                <Chip style={{ margin: '2px' }} variant="outlined" color='primary' label={who.initials} />
              </Tooltip>
            </div>
          ))}
        </ExpansionPanelDetails>
        <ExpansionPanelActions>
          <Button
            onClick={() => imInButtonPressed()}
            color="primary"
            variant='outlined'
            className={classes.button}
            startIcon={isIn() ? <ThumbDownTwoToneIcon /> : <ThumbUpTwoToneIcon />}
            size="small">
            {isIn() ? "I'm Out" : "I'm In"}
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    </div >
  );
}
