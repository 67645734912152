import React, { useContext, useEffect, useRef, MutableRefObject, useCallback, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import Fab from '@material-ui/core/Fab';
import ImageTwoToneIcon from '@material-ui/icons/ImageTwoTone';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FirebaseContext, AuthUserContext } from '../../components/firebase'
import ImageUploadDialog from '../../components/imageuploaddialog'
import cfg from '../../model/db';
import { Event, AuthUserCtxProvider } from '../../model/interfaces'

const useStyles = makeStyles((_theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiTextField-root': {
        width: '99%',
        top: 'auto',
        bottom: 1,
        textAlign: 'center',
        left: 'auto',
        size: 'large',
        position: 'fixed',
      },
    },
    fab: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 80,
      left: 'auto',
      size: 'large',
      position: 'fixed',
    },
    textField: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: 0,
      marginTop: 0,
      fontWeight: 500,
      background: '#FFFFFF',
    },
    input: {
      color: 'black'
    },
  })
})

export default function MessageInput(props: any) {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext)
  const auth: AuthUserCtxProvider = useContext(AuthUserContext)
  const [text, setText] = useState('');
  const msgRef: MutableRefObject<any> = useRef()
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const sendImage = useCallback((base64Img: string) => {

    if (base64Img.length === 0) return

    const createImageMessage = (base64Img: string, initials: string, firstName: string, lastName: string, guid: string) => {
      return {
        type: 'image',
        id: '',
        created: firebase.timestamp(),
        initials,
        firstName,
        lastName,
        guid,
        base64Img,
      };
    }

    const item = msgRef.current.push();
    item.setWithPriority(
      createImageMessage(
        base64Img,
        auth?.userInfo?.user?.initials!,
        auth?.userInfo?.user?.firstName!,
        auth?.userInfo?.user?.lastName!,
        auth?.userInfo?.user?.guid!,
      ),
      firebase.timestamp(),
    );
    setText('')
  }, [auth, firebase]);

  const sendMessage = useCallback(() => {

    if (text.length === 0) return

    const createTextMessage = (text: string, initials: string, firstName: string, lastName: string, guid: string) => {
      return {
        type: 'text',
        id: '',
        created: firebase.timestamp(),
        initials,
        firstName,
        lastName,
        guid,
        text,
      };
    }

    const item = msgRef.current.push();
    item.setWithPriority(
      createTextMessage(
        text,
        auth?.userInfo?.user?.initials!,
        auth?.userInfo?.user?.firstName!,
        auth?.userInfo?.user?.lastName!,
        auth?.userInfo?.user?.guid!,
      ),
      firebase.timestamp(),
    );
    setText('')
  }, [auth, firebase, text]);

  useEffect(() => {

    const keydownHandler = (e: any) => {
      if (e.keyCode === 13 && e.ctrlKey) sendMessage()
    }
    document.addEventListener('keydown', keydownHandler);

    const initFirebaseRef = (selectedEvent: Event) => {
      msgRef.current = firebase
        .getDatabase()
        .ref(`${cfg.root}/${cfg.messageRoot}/`)
        .child(selectedEvent.id);
    };
    initFirebaseRef(props.selectedEvent)
    return (() => document.removeEventListener('keydown', keydownHandler))
  }, [firebase, props.selectedEvent, sendMessage]);

  return (
    <>
      <form className={classes.root} noValidate autoComplete="on">
        <div>
          <TextField
            id="standard-textarea"
            variant={'outlined'}
            rowsMax="4"
            className={classes.textField}
            value={text}
            onChange={handleChange}
            label="Your message"
            placeholder="Start typing here ..."
            multiline
            InputProps={{
              className: classes.input,
              endAdornment: (
                <InputAdornment position="end">
                  <SendIcon color='primary' onClick={sendMessage} />
                </InputAdornment>
              )
            }}
          />
        </div>
      </form>
      <Fab className={classes.fab} color="primary" aria-label="add">
        <ImageTwoToneIcon onClick={e => setUploadDialogOpen(true)} />
      </Fab>
      <ImageUploadDialog uploadDialogOpen={uploadDialogOpen}
        setUploadDialogOpen={setUploadDialogOpen}
        sendImage={sendImage} />
    </>
  );
}
