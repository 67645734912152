import React, { useContext, useEffect, useRef, MutableRefObject } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FirebaseContext } from '../../components/firebase'
import { Copyright } from '../../common/Copyright'
import { useToggleEditingForm as useForm } from '../../hooks/useForm'
import { useHistory } from "react-router-dom"
import { LANDING, SIGN_IN } from '../../common/routes'
import { User } from '../../model/interfaces'
import cfg from '../../model/db';
import golfPic1 from '../../assets/golf1blur.jpg'

const StyledLink = withStyles({
  root: {
    color: 'white',
  },
})(Link);

const StyledTypography = withStyles({
  root: {
    color: 'white',
  },
})(Typography);

const useStyles = makeStyles((theme: any) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    background: '#FFFFFF',
  },
  input: {
    color: 'black'
  },
  bg: {
    backgroundImage: 'url(' + golfPic1 + ')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const { inputs, onChange } = useForm({ initialValues: {} })
  const usersRef: MutableRefObject<any> = useRef()

  const saveUserData = (authUser: firebase.User, firstName: string, lastName: string) => {

    const user: User = {
      firstName: firstName,
      lastName: lastName,
      guid: authUser.uid,
      email: authUser.email,
      initials: `${firstName.charAt(0)}${lastName.charAt(0)}${lastName.charAt(1)}`,
      lastSeen: firebase.timestamp(),
    }

    usersRef
      .current
      .ref(cfg.root)
      .child(cfg.usersRoot + '/' + authUser.uid)
      .set(user);
  }

  useEffect(() => {
    usersRef.current = firebase.getDatabase()
  }, [firebase]);

  const signUp = () => {
    firebase
      .createUserWithEmailAndPassword(inputs.email, inputs.password)
      .then((authUser: any) => {
        saveUserData(authUser.user, inputs.firstName, inputs.lastName)
        history.push(LANDING)
      })
      .catch((error: any) => {
        alert(error)
      });
  }

  return (
    <div className={classes.bg}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <StyledTypography variant="h5">
            Sign up
        </StyledTypography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <TextField
                  autoComplete="fname"
                  margin="normal"
                  name="firstName"
                  variant="filled"
                  className={classes.textField}
                  InputProps={{
                    className: classes.input,
                  }}
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  className={classes.textField}
                  InputProps={{
                    className: classes.input,
                  }}
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  className={classes.textField}
                  InputProps={{
                    className: classes.input,
                  }}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  className={classes.textField}
                  InputProps={{
                    className: classes.input,
                  }}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={onChange}
                />
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={signUp}
              >
                Sign Up
                </Button>
            </Grid>
            <Grid container justify="flex-end">
              <Grid item>
                <StyledLink href={SIGN_IN} variant="body2">
                  Already have an account? Sign in
              </StyledLink>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
