import React, { useContext, useRef, useEffect, MutableRefObject, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Landing } from '../landing'
import { SignUp } from '../signup'
import { SignIn } from '../signin'
import Scores from '../scores'
import Archives from '../archives'
import { MessageList } from "../messagelist";
import * as ROUTES from '../../common/routes';
import { User, UserInfo, AuthUserCtxProvider } from '../../model/interfaces'
import { FirebaseContext, AuthUserContext } from '../../components/firebase'
import cfg from '../../model/db'

const App: React.FC = () => {
  const firebaseCtx = useContext(FirebaseContext)
  const [userInfo, setUserInfo] = useState<UserInfo | null | undefined>(undefined)
  const usersRef: MutableRefObject<any> = useRef()

  const authUserContext: AuthUserCtxProvider = {
    userInfo: userInfo,
    updateUser: (user: UserInfo) => {
      setUserInfo(user)
    }
  }

  useEffect(() => {
    firebaseCtx.auth.onAuthStateChanged((authUser: firebase.User | null) => {
      usersRef.current = firebaseCtx
        .getDatabase()
        .ref(cfg.root)
        .child(cfg.usersRoot + '/' + authUser?.uid)

      usersRef && usersRef.current &&
        usersRef.current.once('value', (snapshot: any) => {
          const user: User = snapshot.val()
          const userInfo: UserInfo = {
            authUser: authUser,
            user: user
          }
          setUserInfo(userInfo)
        })
    });
  }, [firebaseCtx]);

  return (
    <AuthUserContext.Provider value={authUserContext}>
      <Router>
        <Route exact path={ROUTES.LANDING} component={Landing} />
        <Route path={ROUTES.SIGN_UP} component={SignUp} />
        <Route path={ROUTES.SIGN_IN} component={SignIn} />
        <Route path={ROUTES.SCORES} component={Scores} />
        <Route path={ROUTES.ARCHIVES} component={Archives} />
        <Route path={ROUTES.MESSAGE_LIST} component={MessageList} />
      </Router>
    </AuthUserContext.Provider >)
}
export default App;