import React, { useContext, useRef, useEffect, useState, MutableRefObject } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FirebaseContext, AuthUserContext } from '../../components/firebase'
import { EventCardContent } from './eventcard'
import { Event, AuthUserCtxProvider } from '../../model/interfaces'
import cfg from '../../model/db';

const useStyles = makeStyles(theme => ({
    addFab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        size: 'large',
        position: 'fixed',
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const createDefaultEvent = (auth: AuthUserCtxProvider) => {
    return {
        created: new Date(),
        createdBy: {
            firstName: auth?.userInfo?.user?.firstName,
            lastName: auth?.userInfo?.user?.lastName,
            guid: auth?.userInfo?.user?.guid,
            initials: auth?.userInfo?.user?.initials,
        },
        id: "",
        isReserved: false,
        subject: "",
        when: new Date(),
        where: "",
    }
}

export default function EventDialog(props: any) {
    const [open, setOpen] = useState<boolean>(false)
    const firebase = useContext(FirebaseContext)
    const auth: AuthUserCtxProvider = useContext(AuthUserContext)
    const eventsRef: MutableRefObject<any> = useRef()
    const event: Event = props.event ? props.event : createDefaultEvent(auth)

    const handleClickOpen = (e: any) => {
        setOpen(true)
        e.stopPropagation()
    };

    const handleSave = (event: Event) => {
        createOrUpdateEvent(event)
        setOpen(false)
    };

    const handleCancel = (e: any) => {
        setOpen(false);
        e.stopPropagation()
    };

    const buildDateTimeMessage = (
        subject: string,
        where: string,
        when: number,
        isReserved: boolean,
        initials: string,
        firstName: string,
        lastName: string,
        guid?: string,
    ) => {
        return {
            type: 'event',
            id: '',
            created: firebase.timestamp(),
            subject,
            where,
            when,
            isReserved,
            initials,
            firstName,
            lastName,
            guid,
            in: [],
        }
    }

    const createEvent = (event: Event) => {
        const item = eventsRef.current.push();
        item.setWithPriority(
            buildDateTimeMessage(
                event.subject,
                event.where,
                new Date(event.when).getTime(),
                event.isReserved,
                event.createdBy.initials,
                event.createdBy.firstName,
                event.createdBy.lastName,
                auth?.userInfo?.user?.guid,
            ),
            firebase.timestamp(),
        );
    };

    const updateEvent = (event: Event) => {
        const sel = {
            "subject": event.subject,
            "where": event.where,
            "when": event.when,
            "isReserved": event.isReserved,
        };
        eventsRef
            .current
            .child(event.id)
            .update(sel)
            .catch((error: any) => console.log('Update failed:', error, sel));
    };

    const createOrUpdateEvent = (event: Event) => {
        if (event.id === "") {
            createEvent(event);
        } else {
            updateEvent(event);
        }
    };

    const isNewEvent = (id: string) => id === ""

    useEffect(() => {
        eventsRef.current = firebase
            .getDatabase()
            .ref(cfg.root)
            .child(cfg.eventsRoot);
    }, [firebase]);

    const classes = useStyles();
    return (
        <>
            {isNewEvent(event.id) &&
                <Fab className={classes.addFab} color="primary" aria-label="add">
                    <AddIcon onClick={handleClickOpen} />
                </Fab>
            }
            {!isNewEvent(event.id) &&
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleClickOpen}
                    className={classes.button}
                    startIcon={<EditIcon />}
                >
                    Edit
                </Button>
            }
            <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{isNewEvent(event.id) ? 'Create New Event' : 'Edit Event'}</DialogTitle>
                <DialogContent>
                    <EventCardContent event={event} canEdit={open} handleSave={handleSave} handleCancel={handleCancel} />
                </DialogContent>
            </Dialog>
        </>
    )
}