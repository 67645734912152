import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grow from '@material-ui/core/Grow';

export default function MessageImageView(props: any) {
  const { imgUrl, open, setOpen } = props

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="image"
        aria-describedby="image-descr"
      >
        <Grow
          in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 900 } : {})}
        >
          <DialogContent>
            <img src={imgUrl} alt="" />
          </DialogContent>
        </Grow>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
