const cfg = {
  root: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
  groupsRoot: 'groups',
  eventsRoot: 'events',
  messageRoot: 'messages',
  inRoot: 'in',
  outRoot: 'out',
  usersRoot: 'users',
  seenRoot: 'seen',
  scoresRoot: 'scores',
  archiveRoot: 'archive',
};
export default cfg;

/* Data Model
  <whosin-624f8>
    +-(prod | dev)
      +-groups [ID] // Later
        +- [Group Structure]
          :
      +-group [Group.ID]
        +-events [ID]
          +-[Event Structure]
          :
        +-messages [Event.ID]
          +-[Message Structure]
          :
        +-in [Event.ID]
          +-[User.ID]
          :
        +-maybe [Event.ID]
          +-[User.ID]
          :
        +-out [Event.ID]
          +-[User.ID] // Or, can be calculated by users[Group.ID] - in[Event.ID]?
          :
        +-users [Group.ID]
          +-[User Structure with credentials]
            +- uid (from auth) // Was guid
            +- email (from auth)
            +- firstName (from signup)
            +- lastName (from signup)
            +- initials (default calculated from firstName/lastName)
*/