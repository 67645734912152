import React, { useContext, useEffect, useRef, MutableRefObject } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { FirebaseContext, AuthUserContext } from '../../components/firebase'
import { useToggleEditingForm as useForm } from '../../hooks/useForm'
import { makeStyles } from '@material-ui/core/styles';
import { User, AuthUserCtxProvider } from '../../model/interfaces'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import cfg from '../../model/db';

const useStyles = makeStyles((theme: any) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    saveCancelButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'spaceBetween',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(2),
    },
    textField: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: 500,
        background: '#FFFFFF',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    input: {
        color: 'black'
    },
}))

export default function MyProfile(props: any) {
    const classes = useStyles();
    const { setSnackMsg } = props
    const authUser: AuthUserCtxProvider = useContext(AuthUserContext)
    const usersRef: MutableRefObject<any> = useRef()
    const firebase = useContext(FirebaseContext)
    const { inputs, onChange } = useForm({
        initialValues: {
            firstName: authUser?.userInfo?.user?.firstName,
            lastName: authUser?.userInfo?.user?.lastName,
            initials: authUser?.userInfo?.user?.initials
        }
    })
    const { showMyProfile, setShowMyProfile } = props

    const handleClose = () => {
        setShowMyProfile(false)
    };

    const saveProfile = () => {
        const updatedProfile = (): User => {
            const user: User = {
                firstName: inputs.firstName,
                lastName: inputs.lastName,
                initials: inputs.initials,
                email: authUser?.userInfo?.authUser?.email!,
                guid: authUser?.userInfo?.authUser?.uid!,
            }
            return user
        }

        usersRef
            .current
            .child(authUser?.userInfo?.user?.guid)
            .update(updatedProfile())
            .then(() => {
                setSnackMsg("Profile saved.")
                authUser.updateUser({
                    authUser: authUser?.userInfo?.authUser,
                    user: updatedProfile(),
                })
            })
            .catch((error: any) => setSnackMsg(`Update failed: ${error}`))
        setShowMyProfile(false)
    }

    const nameError = (str: string): boolean => str.length === 0
    const initialsError = (str: string): boolean => str.length !== 3
    const errorsInForm = (): boolean => {
        return nameError(inputs.firstName) ||
            nameError(inputs.lastName) ||
            initialsError(inputs.initials)
    }
    const disabled = errorsInForm()

    useEffect(() => {
        usersRef.current = firebase
            .getDatabase()
            .ref(cfg.root)
            .child(cfg.usersRoot);
    }, [firebase]);

    return (
        <Dialog fullScreen open={showMyProfile} onClose={handleClose} >
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <AccountCircle />
                    </Avatar>
                    <Grow
                        in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 700 } : {})}
                    >
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <TextField
                                        autoComplete="fname"
                                        defaultValue={inputs.firstName}
                                        margin="normal"
                                        error={nameError(inputs.firstName)}
                                        helperText={nameError(inputs.firstName) ? "First name, cannot be empty." : ''}
                                        name="firstName"
                                        variant="filled"
                                        placeholder="First name"
                                        className={classes.textField}
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        // autoFocus
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        variant="filled"
                                        margin="normal"
                                        placeholder="Last name"
                                        required
                                        error={nameError(inputs.lastName)}
                                        helperText={nameError(inputs.lastName) ? "Last name, cannot be empty." : ''}
                                        defaultValue={inputs.lastName}
                                        fullWidth
                                        className={classes.textField}
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lname"
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="filled"
                                        margin="normal"
                                        placeholder="Initials, must be 3 characters"
                                        error={initialsError(inputs.initials)}
                                        helperText={initialsError(inputs.initials) ? "Initials, must be 3 characters." : ''}
                                        defaultValue={inputs.initials}
                                        required
                                        fullWidth
                                        className={classes.textField}
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        inputProps={{ minLength: 3, maxLength: 3 }}
                                        name="intials"
                                        label="Initials"
                                        id="initials"
                                        autoComplete="initials"
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth
                                        defaultValue={authUser?.userInfo?.user?.email!}
                                        className={classes.textField}
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Grow>
                </div>
                <DialogActions>
                    <div className={classes.saveCancelButtons}>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            variant="outlined"
                            className={classes.button}
                            startIcon={<CancelIcon />}>
                            Cancel
                        </Button>
                        <Button
                            onClick={saveProfile}
                            color="primary"
                            variant="outlined"
                            className={classes.button}
                            disabled={disabled}
                            startIcon={<SaveIcon />}>
                            Save
                        </Button>
                    </div>
                </DialogActions>
            </Container>
        </Dialog >
    );
}
