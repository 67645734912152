import React from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import { arrayBufferToString } from '../../common/utils'
const emlformat = require('eml-format');

const UploadDialog = (props: any) => {
  const { uploadDialogOpen, setUploadDialogOpen, setScore } = props

  const handleClose = () => {
    setUploadDialogOpen(false)
  }

  const handleSave = (files: string[] | []) => {
    files.forEach((file: any) => {
      const reader = new FileReader()
      reader.onabort = () => console.error('file reading was aborted')
      reader.onerror = () => console.error('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result
        emlformat.read(arrayBufferToString(binaryStr), function (error: any, data: any) {
          if (error) return console.error(error);
          setScore(data.html)
        });
      }
      reader.readAsArrayBuffer(file)
    })
    setUploadDialogOpen(false)
  }

  return (
    <>
      <DropzoneDialog
        open={uploadDialogOpen}
        onSave={handleSave}
        dialogTitle="Upload Score"
        dropzoneText="Drag and drop here the score email file (.eml) or click below"
        acceptedFiles={['message/rfc822']}
        showPreviews={true}
        filesLimit={1}
        submitButtonText="Preview"
        maxFileSize={5000000}
        onClose={handleClose}
      />
    </>
  )
}
export default UploadDialog