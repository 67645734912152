import React, { useContext, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Users from '../users'
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import About from '../about'
import MyProfile from '../myprofile'
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import AlertDialog from '../../components/alertdialog'
import Divider from '@material-ui/core/Divider';
import { FirebaseContext } from '../../components/firebase'
import { useHistory } from "react-router-dom"
import { SIGN_IN, LANDING, SCORES, ARCHIVES } from '../../common/routes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'block',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    sectionDesktop: {
      display: 'flex',
    },
  }),
);

export default function ApppBar(props: any) {
  const classes = useStyles();
  const { title, setSnackMsg } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showMyProfile, setShowMyProfile] = useState(false)
  const [showAbout, setShowAbout] = useState(false)
  const [showUsers, setShowUsers] = useState(false)
  const [showSignOut, setShowSignOut] = useState(false)
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyProfile = () => {
    setShowMyProfile(true)
    handleMenuClose()
  }

  const handleAbout = () => {
    setShowAbout(true)
    handleMenuClose()
  }

  const handleUsers = () => {
    setShowUsers(true)
    handleMenuClose()
  }

  const handleScores = () => {
    setAnchorEl(null);
    history.push(SCORES)
  }

  const handleArchives = () => {
    setAnchorEl(null);
    history.push(ARCHIVES)
  }

  const handleSignOut = () => {
    setAnchorEl(null);
    firebase
      .signOut()
      .then(() => {
        console.log('signed out')
        history.push(SIGN_IN)
      })
      .catch((error: any) => {
        alert(error)
      });
    handleMenuClose()
  };

  const menuId = 'primary-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMyProfile}>My Profile</MenuItem>
      <MenuItem onClick={handleUsers}>Users</MenuItem>
      <MenuItem onClick={handleScores}>Scores</MenuItem>
      <MenuItem onClick={handleArchives}>Archives</MenuItem>
      <MenuItem onClick={handleAbout}>About</MenuItem>
      <Divider />
      <MenuItem onClick={() => setShowSignOut(true)}>Sign Out</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            onClick={() => history.push(LANDING)}
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <HomeTwoToneIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {title}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircleTwoToneIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {showMyProfile && <MyProfile
        setSnackMsg={setSnackMsg}
        showMyProfile={true}
        setShowMyProfile={setShowMyProfile} />}
      {showAbout && <About setShowAbout={setShowAbout} />}
      {showUsers && <Users setShowUsers={setShowUsers} />}
      {showSignOut && <AlertDialog
        msg={'Sign out?'}
        content={''}
        alertDialogOpen={showSignOut}
        setAlertDialogOpen={setShowSignOut}
        doAction={handleSignOut} />}
    </div>
  )
}
