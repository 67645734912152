import { useEffect, useRef, useContext, MutableRefObject } from 'react';
import { FirebaseContext } from '../components/firebase'
import { Score } from '../model/interfaces'
import cfg from '../model/db';

export const useScores = (setScores: any) => {
  const scoresRef: MutableRefObject<any> = useRef()
  const firebase = useContext(FirebaseContext)

  useEffect(() => {
    scoresRef.current = firebase
      .getDatabase()
      .ref(cfg.root)
      .child(cfg.scoresRoot);

    const scoresListener = scoresRef.current.on('value', (snapshot: any) => {
      if (!snapshot ||
        typeof snapshot === 'undefined') {
        setScores([])
        return;
      }

      const fbScores: [Score] = snapshot.val();
      if (fbScores === null) {
        setScores([])
        return
      }

      if (fbScores) {
        const keys = Object.keys(fbScores)
        setScores(keys.map((key: string) => {
          let s = fbScores[key as any]
          s.id = key
          return s
        }).reverse())
      }
    })
    return (() =>
      scoresRef.current ? scoresRef.current.off('value', scoresListener) : null)
  }, [firebase, setScores])
}