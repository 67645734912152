import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  MutableRefObject
} from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import AlertDialog from '../../../components/alertdialog'
import UploadDialog from '../../../components/uploaddialog'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import { useArchiveEvent } from '../../../hooks'
import { Score, Event, AuthUserCtxProvider } from '../../../model/interfaces'
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import DialogActions from '@material-ui/core/DialogActions';
import { FirebaseContext, AuthUserContext } from '../../../components/firebase'
import cfg from '../../../model/db'

const useStyles = makeStyles((theme: any) => ({
  uploadCancelButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'spaceBetween',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
  },
}))

export default function EventCardMenu(props: any) {
  const classes = useStyles();
  const authUser: AuthUserCtxProvider = useContext(AuthUserContext)
  const { event, setSnackMsg } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)
  const [score, setScore] = useState(null)
  const firebase = useContext(FirebaseContext)
  const { archiveEvent } = useArchiveEvent(event, setSnackMsg)
  const scoreRef: MutableRefObject<any> = useRef()
  const ARCHIVE_EVENT_MENU_ITEM = "Archive Event"
  const UPLOAD_SCORE_MENU_ITEM = "Upload Score"

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeScore = () => {
    setScore(null)
  };

  const buildScore = (whenPosted: number, event: Event, scoreCard: string | null): Score => {
    return {
      postedAt: whenPosted,
      postedBy: authUser?.userInfo?.user,
      eventRef: event.id,
      whenPlayed: event.when,
      wherePlayed: event.where,
      scoreCard: scoreCard || "N/A",
    }
  }

  const uploadScore = () => {
    if (!scoreRef || !scoreRef.current) return
    const item = scoreRef.current.push()
    item.setWithPriority(buildScore(firebase.timestamp(), event, score), firebase.timestamp())
    setSnackMsg('Score uploaded.')
    setScore(null)
  };

  const handleClose = (event: any) => {
    if (event.target.innerText.includes(UPLOAD_SCORE_MENU_ITEM)) {
      setUploadDialogOpen(true)
    } else if (event.target.innerText.includes(ARCHIVE_EVENT_MENU_ITEM)) {
      setArchiveDialogOpen(true)
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    scoreRef.current = firebase
      .getDatabase()
      .ref(cfg.root)
      .child(cfg.scoresRoot)
  }, [firebase, props.event.id]);

  return (
    <div>
      <MoreVertIcon aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>{UPLOAD_SCORE_MENU_ITEM}</MenuItem>
        <MenuItem onClick={handleClose}>{ARCHIVE_EVENT_MENU_ITEM}</MenuItem>
      </Menu>
      <AlertDialog
        msg={"Archive Event?"}
        content={`Subject: ${event.subject}`}
        alertDialogOpen={archiveDialogOpen}
        setAlertDialogOpen={setArchiveDialogOpen}
        doAction={archiveEvent} />
      <UploadDialog uploadDialogOpen={uploadDialogOpen}
        setUploadDialogOpen={setUploadDialogOpen}
        setScore={setScore} />
      <Dialog open={score !== null} onClose={handleClose} >
        <div style={{ margin: '10px' }}
          dangerouslySetInnerHTML={{
            __html: score || ''
          }}>
        </div>
        <DialogActions>
          <div className={classes.uploadCancelButtons}>
            <Button
              onClick={closeScore}
              color="primary"
              variant="outlined"
              className={classes.button}
              startIcon={<CancelIcon />}>
              Cancel
            </Button>
            <Button
              onClick={uploadScore}
              color="primary"
              variant="outlined"
              className={classes.button}
              startIcon={<CloudUploadTwoToneIcon />}>
              Upload
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div >
  );
}