import { useEffect, useRef, useContext, MutableRefObject } from 'react';
import { FirebaseContext, AuthUserContext } from '../components/firebase'
import { Event, AuthUserCtxProvider } from '../model/interfaces'
import cfg from '../model/db';

export const useSeenCount = (selectedEvent: Event, userId: string | undefined) => {
    const seenCountRef: MutableRefObject<any> = useRef()
    const eventsRef: MutableRefObject<any> = useRef()
    const usersRef: MutableRefObject<any> = useRef()
    const auth: AuthUserCtxProvider = useContext(AuthUserContext)
    const firebase = useContext(FirebaseContext)
    let seenCount: MutableRefObject<any> = useRef({ count: 0 })

    const updateSeenCount = (newCount: number) => {
        const updateCount = () => {
            if (!seenCountRef.current) return
            seenCountRef
                .current
                .child(userId)
                .update({ count: newCount })
                .catch((error: any) => console.log('SeenCount update failed:', error, { count: newCount }));
        }

        const updateLastSeen = () => {
            if (!usersRef.current) return
            usersRef
                .current
                // .child(userId)
                .update({ lastSeen: firebase.timestamp() })
                .catch((error: any) => console.log('LastSeen update failed:', error));
        }

        // Check if the count is 0 due to event deletion
        if (newCount === 0) {
            if (!eventsRef || !eventsRef.current) return
            eventsRef.current.once('value', (snapshot: any) => {
                if (!snapshot.exists()) return
                updateCount()
                updateLastSeen()
            })
        } else {
            updateCount()
            updateLastSeen()
        }
    }

    // seenCount.current = {fbKey: {count: <number>}}
    const getSeenCount = (): number => {
        const keys = Object.keys(seenCount.current)
        if (keys.length === 0) return 0
        for (let key of keys) {
            if (key === userId) return seenCount.current[key].count
        }
        return 0
    }

    useEffect(() => {
        const initFirebaseRefs = (selectedEvent: Event) => {

            if (typeof auth.userInfo?.user?.guid === 'undefined') return

            seenCountRef.current = firebase
                .getDatabase()
                .ref(`${cfg.root}/${cfg.seenRoot}/`)
                .child(selectedEvent.id)

            eventsRef.current = firebase
                .getDatabase()
                .ref(`${cfg.root}/${cfg.eventsRoot}/`)
                .child(selectedEvent.id)

            usersRef.current = firebase
                .getDatabase()
                .ref(`${cfg.root}/${cfg.usersRoot}/`)
                .child(auth.userInfo?.user.guid)
        };

        const initSeenCountListener = () => {
            if (!seenCountRef || !seenCountRef.current) return
            return seenCountRef.current
                .on('value', (snapshot: any) => {
                    if (!snapshot || typeof snapshot === 'undefined') {
                        seenCount.current = 0
                        return;
                    }
                    const count: number = snapshot.val()
                    seenCount.current = !count ? 0 : count
                })
        }

        initFirebaseRefs(selectedEvent)
        const listener = initSeenCountListener()
        return (() => seenCountRef.current ? seenCountRef.current.off('value', listener) : null)
    }, [firebase, selectedEvent, auth.userInfo]);

    return { getSeenCount, updateSeenCount }
}
