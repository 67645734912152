import React, { useContext, useEffect, useState, useRef, MutableRefObject } from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Grow from '@material-ui/core/Grow';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { FirebaseContext } from '../../components/firebase'
import { User } from '../../model/interfaces'
import cfg from '../../model/db';
import { formatAgo } from '../../common/utils'

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: 'darkGreen',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
    }),
)(TableRow);

const useStyles = makeStyles({
    table: {
        // minWidth: 400,
    },
});

export default function Users(props: any) {
    const classes = useStyles();
    const { setShowUsers } = props
    const usersRef: MutableRefObject<any> = useRef()
    const firebase = useContext(FirebaseContext)
    const [users, setUsers] = useState<User[]>([])

    const ok = () => {
        setShowUsers(false)
    }

    useEffect(() => {
        usersRef.current = firebase
            .getDatabase()
            .ref(cfg.root)
            .child(cfg.usersRoot);

        const usersListener = usersRef.current.on('value', (snapshot: any) => {

            const updateId = (users: [User], key: any) => {
                const u = users[key];
                u.id = key;
                return u;
            };

            if (!snapshot || typeof snapshot === 'undefined') return;
            const users: [User] = snapshot.val()
            if (!users || typeof users === 'undefined') {
                setUsers([])
            } else {
                const keys = Object.keys(users);
                let theUsers: User[] = keys.map((key: string) => updateId(users, key))
                setUsers(theUsers)
            }
        });
        return () => usersRef.current ? usersRef.current.off('value', usersListener) : null
    }, [firebase]);

    return (
        <>
            <Dialog
                open={true}
                onClose={ok}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Users</DialogTitle>
                <Grow
                    in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}
                >
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">First Name</StyledTableCell>
                                        <StyledTableCell align="left">Last Name</StyledTableCell>
                                        <StyledTableCell align="left">Intials</StyledTableCell>
                                        <StyledTableCell align="left">Last Seen</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map(row => (
                                        <StyledTableRow key={row.guid}>
                                            <StyledTableCell align="left" component="th" scope="row">
                                                {row.firstName}
                                            </StyledTableCell>
                                            <StyledTableCell align="left" component="th" scope="row">
                                                {row.lastName}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{row.initials}</StyledTableCell>
                                            <StyledTableCell align="left">{row.lastSeen ? formatAgo(row.lastSeen) : 'Unknown'}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Grow>
                <DialogActions>
                    <Button onClick={ok} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
