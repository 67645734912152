import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  MutableRefObject
} from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { AuthState, authState } from '../../common/utils'
import { SignIn } from '../signin'
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { Score, AuthUserCtxProvider } from '../../model/interfaces'
import { useScores } from '../../hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import { AuthUserContext } from '../../components/firebase'
import AlertDialog from '../../components/alertdialog'
import ScoreCard from './scorecard'
import { formatDate, formatAgo } from '../../common/utils'
import { FirebaseContext } from '../../components/firebase'
import { ApppBar } from '../app/'
import cfg from '../../model/db';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: 'darkGreen',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(5),
      },
    },
    tableContainer: {
      marginTop: '60px'
    },
  }),
);

export default function Scores() {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext)
  const authUser: AuthUserCtxProvider = useContext(AuthUserContext)
  const [scores, setScores] = useState<Score[] | null>(null)
  const [selectedScore, setSelectedScore] = useState<Score | null>(null)
  const [showScoreCard, setShowScoreCard] = useState(false)
  const [alertDialogOpen, setAlertDialogOpen] = useState(false)
  const [snackMsg, setSnackMsg] = useState<string | null>(null);
  const scoresRef: MutableRefObject<any> = useRef()
  useScores(setScores)

  const selected = (score: Score) => {
    setSelectedScore(score)
    setShowScoreCard(true)
  }

  const confirmDelete = (score: Score) => {
    setSelectedScore(score)
    setAlertDialogOpen(true)
  }

  const deleteScoreCard = () => {
    scoresRef
      .current
      .child(selectedScore?.id)
      .remove()
      .then(() => {
        setSnackMsg('Scorecard deleted.')
      })
      .catch((error: any) =>
        setSnackMsg(`Delete failed: ${error}`)
      )
  }

  const closeScoreCard = () => {
    setShowScoreCard(false)
  }

  useEffect(() => {
    scoresRef.current = firebase
      .getDatabase()
      .ref(`${cfg.root}/${cfg.scoresRoot}`)
  }, [firebase]);

  return (
    <>
      <ApppBar title={'Scores'} setSnackMsg={setSnackMsg} />
      {authState(authUser.userInfo) === AuthState.NOT_SIGNED_IN && <SignIn />}
      {
        authState(authUser.userInfo) === AuthState.UNKNOWN && !scores && <div className={classes.root}>
          <CircularProgress />
        </div>
      }
      {
        authState(authUser.userInfo) === AuthState.SIGNED_IN && <React.Fragment>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Posted At</StyledTableCell>
                  <StyledTableCell align="left">Posted By</StyledTableCell>
                  <StyledTableCell align="left">Date Played</StyledTableCell>
                  <StyledTableCell align="left">Location</StyledTableCell>
                  <StyledTableCell align="left">Score Card</StyledTableCell>
                  <StyledTableCell align="left">Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 500 } : {})}>
                <TableBody>
                  {scores && scores.map((row: Score) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {formatAgo(row.postedAt)}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.postedBy?.initials}</StyledTableCell>
                      <StyledTableCell align="left">{formatDate(row.whenPlayed)}</StyledTableCell>
                      <StyledTableCell align="left">{row.wherePlayed}</StyledTableCell>
                      <StyledTableCell align="left">
                        <IconButton style={{ color: 'darkBlue' }} onClick={e => selected(row)}>
                          <ListAltTwoToneIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <IconButton style={{ color: 'red' }} onClick={e => confirmDelete(row)}>
                          <DeleteTwoToneIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Grow>
            </Table>
            {scores && scores.length === 0 && <h2> No scores</h2>}
          </TableContainer>
          <ScoreCard showScoreCard={showScoreCard} closeScoreCard={closeScoreCard} scoreCard={selectedScore?.scoreCard} />
        </React.Fragment>}
      <AlertDialog msg='Delete Scorecard?'
        alertDialogOpen={alertDialogOpen}
        setAlertDialogOpen={setAlertDialogOpen}
        doAction={deleteScoreCard} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!snackMsg}
        autoHideDuration={4000}
        onClose={() => setSnackMsg(null)}
        message={snackMsg} />
    </>
  )
}

