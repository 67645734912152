import React from "react";
import packageJson from '../../../package.json';
import Typography from '@material-ui/core/Typography';
import { Copyright } from "../../common/Copyright";
import Dialog from '@material-ui/core/Dialog';
import Grow from '@material-ui/core/Grow';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function About(props: any) {
  const { setShowAbout } = props

  const ok = () => {
    setShowAbout(false)
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={ok}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">About</DialogTitle>
        <Grow
          in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}
        >
          <DialogContent>
            < React.Fragment >
              <Typography variant="h4" color="textSecondary" align="center">
                WhoIs In?
            </Typography>
              <Typography variant="h6" color="textPrimary" align="center">
                Version {packageJson.version}
              </Typography>
              <Copyright />
            </React.Fragment >
          </DialogContent>
        </Grow>
        <DialogActions>
          <Button onClick={ok} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}