import React, { useState, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors'
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import { MessageCard } from './messagecard'
import CircularProgress from '@material-ui/core/CircularProgress'
import WhosIn from '../whosin'
import Grow from '@material-ui/core/Grow';
import * as Scroll from 'react-scroll';
import { Element } from 'react-scroll'
import { SignIn } from '../signin'
import { ApppBar } from '../app/'
import { AuthUserContext } from '../../components/firebase'
import { AuthState, authState } from '../../common/utils'
import { MessageInput } from './'
import { Message, AuthUserCtxProvider } from '../../model/interfaces'
import { useMessages, useSeenCount } from '../../hooks'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(5),
    },
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(10),
    backgroundColor: blueGrey[50],
  },
}));

const MessageList = (props: any) => {
  const selectedEvent = props.location.state.selectedEvent
  const authUser: AuthUserCtxProvider = useContext(AuthUserContext)
  const [snackMsg, setSnackMsg] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[] | null>(null)
  const scroll = Scroll.animateScroll;
  useMessages(selectedEvent, setMessages, true)
  const { updateSeenCount } = useSeenCount(selectedEvent,
    authUser.userInfo?.user?.guid)

  if (authState(authUser.userInfo) === AuthState.SIGNED_IN) {
    updateSeenCount(!messages ? 0 : messages.length)
    scroll.scrollToBottom({ duration: 500, smooth: true });
    scroll.scrollMore(10000, { duration: 500, smooth: true })
  }
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      {authState(authUser.userInfo) === AuthState.NOT_SIGNED_IN && <SignIn />}
      {authState(authUser.userInfo) === AuthState.UNKNOWN && !messages && <div className={classes.root}>
        <CircularProgress />
      </div>}
      {authState(authUser.userInfo) === AuthState.SIGNED_IN && <React.Fragment>
        <main>
          <ApppBar title={selectedEvent.subject} setSnackMsg={setSnackMsg} />
          <Container className={classes.cardGrid} maxWidth="md">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <WhosIn event={selectedEvent} />
              </Grid>
              {messages && messages.length > 0 && messages.map(message => (
                <Grow key={message.id}
                  in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 500 } : {})}
                >
                  <Grid item key={message.id} sm={3} >
                    <MessageCard
                      selectedEvent={selectedEvent}
                      message={message}
                      setSnackMsg={setSnackMsg} />
                  </Grid>
                </Grow>
              ))}
              {messages && messages.length === 0 && <h2>No messages</h2>}
              <Element name="listEnd" className="element" />
            </Grid>
          </Container>
        </main>
      </React.Fragment>
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!snackMsg}
        autoHideDuration={4000}
        onClose={() => setSnackMsg(null)}
        message={snackMsg} />
      <MessageInput
        selectedEvent={props.location.state.selectedEvent} />
    </>
  )
}
export default MessageList