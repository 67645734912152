import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
import { Message, User } from '../../../model/interfaces'
import { formatDate, asUrl } from '../../../common/utils'
import golfPic1 from '../../../assets/golf1.jpg'
import golfPic2 from '../../../assets/golf2.jpg'
import golfPic3 from '../../../assets/golf3.jpg'
import golfPic4 from '../../../assets/golf4.jpg'

const pics = [golfPic1, golfPic2, golfPic3, golfPic4]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: 600,
      height: 750,
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    uploadCancelButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'spaceBetween',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(2),
    },
  }),
);

const ArchiveCard = (props: any) => {
  const classes = useStyles();
  const { showArchiveCard, closeArchiveCard, archiveCard } = props

  const InChip = (props: any) => {
    const { user } = props
    return (
      <>
        <Tooltip title={`${user.firstName} ${user.lastName}`}>
          <Chip style={{ margin: '1px' }} variant="outlined" color='primary' label={user.initials} />
        </Tooltip>
      </>
    )
  }

  const inners = (inners: User[]) => {
    if (!inners) return []
    return inners.map((i: User) => <InChip key={i.id} user={i} />)
  }

  if (!showArchiveCard) return null
  return (
    <Dialog open={showArchiveCard} onClose={closeArchiveCard} >
      {<div style={{ margin: '5px', display: 'flex', flexDirection: 'row' }}>{inners(archiveCard.in)}</div>}
      <div className={classes.root}>
        <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 500 } : {})}>
          <GridList cellHeight={180} className={classes.gridList}>
            <GridListTile key="Subheader" cols={3} style={{ height: 'auto' }}>
              <ListSubheader color='primary' disableSticky={true} component="h2">{archiveCard.event.where} on {formatDate(archiveCard.event.when)}</ListSubheader>
            </GridListTile>
            {archiveCard.messages && archiveCard.messages.length > 0 && archiveCard.messages.map((m: Message) => (
              <GridListTile key={m.id}>
                {m?.base64Img && <img src={asUrl(m.base64Img)} alt={''} />}
                <GridListTileBar
                  subtitle={<span>from {m.initials}</span>}
                />
              </GridListTile>
            ))}
            {!(archiveCard.messages && archiveCard.messages.length > 0) && pics.map((p: string, idx: number) => (
              <GridListTile key={idx}>
                <img src={p} alt={''} />
              </GridListTile>
            ))}
            <div style={{ margin: '10px' }}
              dangerouslySetInnerHTML={{
                __html: archiveCard.score ? archiveCard.score.scoreCard : ''
              }}>
            </div>
          </GridList>
        </Grow>
      </div>
      <DialogActions>
        <div className={classes.uploadCancelButtons}>
          <Button
            onClick={e => closeArchiveCard()}
            color="primary"
            variant="outlined"
            className={classes.button}
            startIcon={<CancelIcon />}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog >
  )
}
export default ArchiveCard
