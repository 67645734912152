import React, { useState, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
// import Ticker from 'react-ticker'
import { blueGrey } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import { EventCard } from './eventcard'
import Grow from '@material-ui/core/Grow';
import { ApppBar } from '../app/'
import { Event, AuthUserCtxProvider } from '../../model/interfaces'
import { AuthUserContext } from '../../components/firebase'
import { useEvents } from '../../hooks'
import EventDialog from './EventDialog'
import { AuthState, authState } from '../../common/utils'
import { SignIn } from '../signin'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(5),
      },
    },
    cardGrid: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(8),
      backgroundColor: blueGrey[50],
    },
  }),
);

const EventList = () => {
  const authUser: AuthUserCtxProvider = useContext(AuthUserContext)
  const [snackMsg, setSnackMsg] = useState<string | null>(null);
  const [eventCount, setEventCount] = useState<Number>(0)
  const [events, setEvents] = useState<Event[] | null>(null)
  useEvents(eventCount, setEventCount, setEvents)

  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      {authState(authUser.userInfo) === AuthState.NOT_SIGNED_IN && <SignIn />}
      {authState(authUser.userInfo) === AuthState.UNKNOWN && !events && <div className={classes.root}>
        <CircularProgress />
      </div>}
      {authState(authUser.userInfo) === AuthState.SIGNED_IN && <React.Fragment>
        <main>
          <ApppBar title={'Upcoming Events'} setSnackMsg={setSnackMsg} />
          <Container className={classes.cardGrid} maxWidth="md">
            {/* <Ticker mode='await'>
              {({ index }) => (
                  <h1 style={{color: 'red'}}>Feliz Covidad!</h1>
              )}
            </Ticker> */}
            <Grid container spacing={4}>
              {events && events.length > 0 && events.map(event => (
                <Grow key={event.id}
                  in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 500 } : {})}
                >
                  <Grid item key={event.id} xs={12} sm={6} md={4}>
                    <EventCard event={event} canEdit={false} setSnackMsg={setSnackMsg} />
                  </Grid>
                </Grow>
              ))}
              {events && events.length === 0 && <h2>No events</h2>}
            </Grid>
          </Container>
        </main>
        <EventDialog />
      </React.Fragment>
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!snackMsg}
        autoHideDuration={4000}
        onClose={() => setSnackMsg(null)}
        message={snackMsg} />
    </>
  )
}
export default EventList
