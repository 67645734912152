import React, { useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CancelIcon from '@material-ui/icons/Cancel';
import SubjectIcon from '@material-ui/icons/Subject';
import SaveIcon from '@material-ui/icons/Save';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import InputAdornment from '@material-ui/core/InputAdornment';
import CardMedia from '@material-ui/core/CardMedia';
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Linkify from 'react-linkify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import WhosIn from '../../whosin'
import { makeStyles } from '@material-ui/core/styles';
import pic1 from '../../../assets/golf1.jpg'
import pic2 from '../../../assets/golf2.jpg'
import pic3 from '../../../assets/golf3.jpg'
import pic4 from '../../../assets/golf4.jpg'
import pic5 from '../../../assets/golf5.jpg'
import pic6 from '../../../assets/golf6.jpg'
import pic7 from '../../../assets/golf7.jpg'
import pic8 from '../../../assets/golf8.jpg'
import pic9 from '../../../assets/golf9.jpg'
import pic10 from '../../../assets/golf10.jpg'
import { useToggleEditingForm as useForm } from '../../../hooks/useForm'
import DateFnsUtils from "@date-io/date-fns";
import { formatDate, formatTime, clone } from '../../../common/utils'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  saveCancelButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'spaceBetween',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(2),
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  cardMedia: {
    paddingTop: '23.0%',
  },
  iconText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(1),
  },
  icon: {
    marginEnd: '6px',
    fill: 'darkGreen',
    width: 32,
    height: 28,
  }
}));

const pics = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10]

export const EventCardContent = (props: any) => {
  const { event, canEdit, handleCancel, handleSave } = props
  const [isReserved, setIsReserved] = useState<boolean>(event.isReserved)

  let selectedEvent = clone(event)
  const { inputs, onChange } = useForm({
    initialValues: {
      subject: event.subject,
      where: event.where
    }
  })

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    selectedEvent.when,
  );

  const handleChange = (event: any) => {
    setIsReserved(event.target.checked)
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
  };

  const prepareToSave = (e: any) => {
    e.stopPropagation()
    selectedEvent.subject = inputs.subject
    selectedEvent.where = inputs.where
    selectedEvent.isReserved = isReserved
    selectedEvent.when = selectedDate
    handleSave(selectedEvent)
  }

  const subjectBlock = () => {
    if (canEdit)
      return (
        <TextField
          required
          defaultValue={inputs.subject}
          id="subject"
          name="subject"
          error={!inputs.subject}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SubjectIcon />
              </InputAdornment>
            ),
          }}
          label="Event Subject"
          fullWidth
          placeholder="Subject of this event"
          multiline
          variant='standard'
          rowsMax={3}
          margin='normal'
          autoComplete="fname"
          onChange={onChange}
        />
      )
  }

  const locationBlock = () => {
    if (canEdit)
      return (
        <div className={classes.iconText}>
          <TextField
            required
            defaultValue={inputs.where}
            error={!inputs.where}
            placeholder="Location of this event"
            id="where"
            name="where"
            rowsMax={3}
            variant='standard'
            label="Event Location"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            multiline
            margin='normal'
            autoComplete="fname"
            onChange={onChange}
          />
        </div>
      )
    else return (
      <div className={classes.iconText}>
        <LocationOnIcon className={classes.icon} />
        <Typography variant="body2" gutterBottom color="primary" component="h3">
          <Linkify>
            {event.where}
          </Linkify>
        </Typography>
      </div>
    )
  }

  const editDateTimeBlock = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          fullWidth
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id={`datepicker-${event.id}`}
          label="Event Date"
          value={selectedDate}
          onChange={canEdit ? handleDateChange : f => f}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          fullWidth
          id={`timepicker-${event.id}`}
          label="Event Time"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </MuiPickersUtilsProvider>
    )
  }

  const showDateTimeBlock = () => {
    return (
      <div>
        <div className={classes.iconText}>
          <EventIcon className={classes.icon} />
          <Typography variant="body2" gutterBottom color="primary" component="h3">
            {formatDate(event.when)}
          </Typography>
        </div>
        <div className={classes.iconText}>
          <ScheduleIcon className={classes.icon} />
          <Typography variant="body2" gutterBottom color="primary" component="h3">
            {formatTime(event.when)}
          </Typography>
        </div>
      </div>
    )
  }

  const dateTimeBlock = () => canEdit ? editDateTimeBlock() : showDateTimeBlock()

  const isReservedBlock = () => {
    if (canEdit) return (
      <div className={classes.iconText}>
        <FormControlLabel
          control={
            <Switch
              disabled={!canEdit}
              checked={isReserved}
              onChange={handleChange}
            />
          }
          label={isReserved ? 'Reserved' : ' Not Reserved'}
        />
      </div>
    )
    else return (
      <div className={classes.iconText}>
        {event.isReserved ?
          <CheckCircleTwoToneIcon className={classes.icon} /> :
          <HelpTwoToneIcon style={{ fill: "red" }} className={classes.icon} />}
        <Typography variant="body2" gutterBottom color={event.isReserved ? 'primary' : 'secondary'} component="h3">
          {event.isReserved ? 'Reserved' : ' Not Reserved'}
        </Typography>
      </div>
    )
  }

  const saveCancelBlock = () => {
    if (canEdit) return (
      <div className={classes.saveCancelButtons}>
        <Button
          onClick={handleCancel}
          color="primary"
          variant="outlined"
          size="small"
          className={classes.button}
          startIcon={<CancelIcon />}>
          Cancel
          </Button>
        <Button
          onClick={prepareToSave}
          color="primary"
          size="small"
          variant="outlined"
          disabled={!inputs.subject.trim()}
          className={classes.button}
          startIcon={<SaveIcon />}>
          Save
          </Button>
      </div>
    )
    else return null
  }

  const classes = useStyles();
  return (
    <>
      <CardMedia
        className={classes.cardMedia}
        image={pics[event.created % 10]}
        title="Se on vaan semmosta."
      />
      {!canEdit && <WhosIn event={event} />}
      <Grow
        in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 900 } : {})}
      >
        <CardContent className={classes.cardContent}>
          {subjectBlock()}
          {locationBlock()}
          {dateTimeBlock()}
          {isReservedBlock()}
          {saveCancelBlock()}
        </CardContent >
      </Grow>
    </>
  )
}