import React, { useState, useCallback, useEffect, useContext, useRef, MutableRefObject } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FirebaseContext } from '../../components/firebase'
import cfg from '../../model/db';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((_theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiTextField-root': {
        width: '99%',
        top: 'auto',
        bottom: 1,
        textAlign: 'center',
        left: 'auto',
        size: 'large',
        position: 'fixed',
      },
    },
    textField: {
      width: '100%',
      minWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: 0,
      marginTop: 0,
      fontWeight: 500,
      background: '#FFFFFF',
    },
    input: {
      color: 'black'
    },
  })
})

const EditMessage = (props: any) => {
  const classes = useStyles();
  const { openEditMsg, setOpenEditMsg, msg, eventId, setSnackMsg } = props
  const [text, setText] = useState(msg.text);
  const firebase = useContext(FirebaseContext)
  const msgRef: MutableRefObject<any> = useRef()

  const handleClose = () => {
    setOpenEditMsg(false);
  };

  const saveMessage = useCallback(() => {
    if (text.length === 0) return

    if (!msgRef.current) return
    msgRef
      .current
      .child(msg.id)
      .update({ text: text, created: firebase.timestamp() })
      .then(setSnackMsg('Message updated.'))
      .catch((error: any) => console.log('Message update failed:', error, { text: text }));

    setText('')
    setOpenEditMsg(false);
  }, [firebase, setSnackMsg, text, msg.id, setOpenEditMsg]);

  useEffect(() => {
    const initFirebaseRef = (eventId: string) => {
      msgRef.current = firebase
        .getDatabase()
        .ref(`${cfg.root}/${cfg.messageRoot}/`)
        .child(eventId)
    };
    initFirebaseRef(props.eventId)
  }, [firebase, props.eventId, eventId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    event.preventDefault()
  };

  return (
    <Dialog
      open={openEditMsg}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Edit Message"}</DialogTitle>
      <DialogContent>
        <TextField
          id="standard-textarea"
          variant={'outlined'}
          rowsMax="8"
          className={classes.textField}
          value={text}
          onChange={handleChange}
          label="Your message"
          placeholder="Start typing here ..."
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={text && text.length === 0} onClick={saveMessage} color="primary">
          Save
          </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Cancel
          </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditMessage