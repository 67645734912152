import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  MutableRefObject
} from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlertDialog from '../../../components/alertdialog'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditMessage from '../EditMessage'
import { FirebaseContext } from '../../../components/firebase'
import cfg from '../../../model/db';

export default function MessageCardMenu(props: any) {
  const { message, selectedEvent, setSnackMsg } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false)
  const [openEditMsg, setOpenEditMsg] = useState(false)
  const firebase = useContext(FirebaseContext)
  const msgRef: MutableRefObject<any> = useRef()
  const DELETE_MESSAGE_MENU_ITEM = "Delete Message"
  const EDIT_MESSAGE_MENU_ITEM = "Edit Message"

  const handleClick = (message: any) => {
    message.preventDefault()
    setAnchorEl(message.currentTarget);
  };

  const deleteMessage = () => {
    msgRef
      .current
      .child(selectedEvent.id)
      .child(message.id)
      .remove()
      .then(() => {
        setSnackMsg('Message deleted.')
      })
      .catch((error: any) =>
        setSnackMsg(`Delete failed: ${error}`)
      )
  }

  const handleClose = (e: any) => {
    e.preventDefault()
    if (e.target.innerText.includes(DELETE_MESSAGE_MENU_ITEM)) {
      setAlertDialogOpen(true)
    } else if (e.target.innerText.includes(EDIT_MESSAGE_MENU_ITEM)) {
      setOpenEditMsg(true)
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    msgRef.current = firebase
      .getDatabase()
      .ref(`${cfg.root}/${cfg.messageRoot}`)
  }, [firebase]);

  const MSG = "Delete Message?"
  return (
    <div>
      <MoreVertIcon aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} />
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={(e: any) => handleClose(e)}>{DELETE_MESSAGE_MENU_ITEM}</MenuItem>
        <MenuItem onClick={(e: any) => handleClose(e)}>{EDIT_MESSAGE_MENU_ITEM}</MenuItem>
      </Menu>
      <AlertDialog
        msg={MSG}
        content={``}
        alertDialogOpen={alertDialogOpen}
        setAlertDialogOpen={setAlertDialogOpen}
        doAction={deleteMessage} />
      {openEditMsg && <EditMessage
        openEditMsg={openEditMsg}
        setOpenEditMsg={setOpenEditMsg}
        msg={message}
        setSnackMsg={setSnackMsg}
        eventId={selectedEvent.id} />}
    </div >
  );
}