import { useEffect, useRef, useContext, MutableRefObject } from 'react';
import { FirebaseContext } from '../components/firebase'
import { Event, Message } from '../model/interfaces'
import { useHistory } from 'react-router-dom'
import cfg from '../model/db';

export const useMessages = (selectedEvent: Event, setMessages: any, canGoBack: Boolean) => {
    const msgRef: MutableRefObject<any> = useRef()
    const eventsRef: MutableRefObject<any> = useRef()
    const firebase = useContext(FirebaseContext)
    const history = useHistory()

    useEffect(() => {
        const initFirebaseRefs = (selectedEvent: Event) => {
            msgRef.current = firebase
                .getDatabase()
                .ref(`${cfg.root}/${cfg.messageRoot}/`)
                .child(selectedEvent.id);

            eventsRef.current = firebase
                .getDatabase()
                .ref(`${cfg.root}/${cfg.eventsRoot}/`)
                .child(selectedEvent.id);
        };

        const initEventsListener = () => {
            return eventsRef.current.on('value', (snapshot: any) => {
                if (snapshot.exists()) return
                if (canGoBack) {
                    history.goBack()
                    return
                }
            })
        }

        const initMessageListener = () => {
            const updateId = (msgs: [Message], key: any) => {
                const msg = msgs[key];
                msg.id = key;
                return msg;
            };

            return msgRef.current.orderByKey().on('value', (snapshot: any) => {
                if (!snapshot ||
                    typeof snapshot === 'undefined' ||
                    snapshot.val() === null) {
                    setMessages([])
                    return;
                }
                const msgs = snapshot.val();
                if (msgs) {
                    const keys = Object.keys(msgs)
                    setMessages(keys.map(key => updateId(msgs, key)))
                }
            });
        };

        initFirebaseRefs(selectedEvent)
        const msgListener = initMessageListener()
        const eventsListener = initEventsListener()

        return (() => {
            if (msgRef.current) msgRef.current.off('value', msgListener)
            if (eventsRef.current) eventsRef.current.off('value', eventsListener)
        })
    }, [firebase, selectedEvent, setMessages, canGoBack, history]);
}