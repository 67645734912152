import 'firebase/database';

export interface User {
  firstName: string,
  lastName: string,
  guid: string, // key to users object
  id?: string, // Used in In list
  initials: string,
  email: string | null,
  lastSeen?: number
}

// AuthUserContext
export interface UserInfo {
  authUser: firebase.User | null | undefined,
  user: User
}

export interface AuthUserCtxProvider {
  userInfo: UserInfo | null | undefined,
  updateUser: (userInfo: UserInfo) => void,
}

export interface Event {
  created: number,
  createdBy: User,
  id: string,
  isReserved: boolean,
  subject: string,
  when: number,
  where: string,
}

export interface Score {
  id?: string,
  postedAt: number,
  postedBy?: User,
  eventRef: string,
  whenPlayed: number,
  wherePlayed: string,
  scoreCard: string,
}

export interface Message {
  created: number,
  firstName: User,
  guid: string,
  id: string,
  initials: string,
  lastName: string,
  text?: string,
  base64Img?: string,
  type: string // 'text' or 'image'
}

// Save properties as separate items under the id node.
export interface Archive {
  id?: string,
  event: Event,
  in: User[] | [],
  messages: Message[] | [],
  score: Score | null,
}

export const fbEventToEvent = (events: [FbEvent], key: string): Event => {
  const fbEvent = events[key as any];
  return {
    created: fbEvent.created,
    createdBy: {
      firstName: fbEvent.firstName,
      lastName: fbEvent.lastName,
      guid: fbEvent.guid,
      initials: fbEvent.initials,
      email: fbEvent.email ? fbEvent.email : ''
    },
    id: key,
    isReserved: fbEvent.isReserved,
    subject: fbEvent.subject,
    when: fbEvent.when,
    where: fbEvent.where
  }
};

export const buildEvent = (eventId: string, fbEvent?: FbEvent) => {
  return {
    created: fbEvent ? (fbEvent.created ? fbEvent.created : 0) : 0,
    createdBy: {
      firstName: fbEvent ? (fbEvent.firstName ? fbEvent.firstName : '') : '',
      lastName: fbEvent ? (fbEvent.lastName ? fbEvent.lastName : '') : '',
      guid: fbEvent ? (fbEvent.guid ? fbEvent.guid : '') : '',
      initials: fbEvent ? (fbEvent.initials ? fbEvent.initials : '') : '',
      email: fbEvent ? (fbEvent.email ? fbEvent.email : '') : ''
    },
    id: eventId,
    isReserved: true,
    subject: fbEvent ? (fbEvent.subject ? fbEvent.subject : '') : '',
    when: fbEvent ? (fbEvent.when ? fbEvent.when : 0) : 0,
    where: fbEvent ? (fbEvent.where ? fbEvent.where : '') : '',
  }
}

export type FbEvent = Event & User