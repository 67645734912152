import { useEffect, useRef, useContext, MutableRefObject } from 'react';
import { FirebaseContext } from '../components/firebase'
import { Archive, buildEvent, Event, FbEvent, Message } from '../model/interfaces'
import cfg from '../model/db';

export const useArchiveEvent = (event: Event, setSnackMsg: any, ) => {
  const archiveRef: MutableRefObject<any> = useRef()
  const eventsRef: MutableRefObject<any> = useRef()
  const msgRef: MutableRefObject<any> = useRef()
  const inRef: MutableRefObject<any> = useRef()
  const scoreRef: MutableRefObject<any> = useRef()
  const seenCountRef: MutableRefObject<any> = useRef()
  const firebase = useContext(FirebaseContext)

  const doNotArchive = (archive: Archive) => (archive.messages.length === 0) && (!archive.score || !archive.score.scoreCard)

  const saveArchive = (archive: Archive) => {
    if (!archiveRef || !archiveRef.current) return
    if (doNotArchive(archive)) {
      setSnackMsg('Empty event was not archived.')
      return
    }
    const item = archiveRef.current.push()
    item.setWithPriority(archive, firebase.timestamp())
    setSnackMsg('Event archived.')
  };

  const archiveEvent = () => {
    let archive: Archive = { event: buildEvent(event.id), in: [], messages: [], score: null }

    const doEvent = () => {
      if (!eventsRef || !eventsRef.current) return
      eventsRef.current.child(event.id).once("value", (data: any) => {
        const fbEvent: FbEvent = data.val();
        if (fbEvent) {
          archive.event = buildEvent(event.id, fbEvent)
        } else {
          console.error("Empty event:", event.id)
        }
        doMessages()
      });
    }

    const doMessages = () => {
      if (!msgRef || !msgRef.current) return
      msgRef.current.child(event.id).once("value", (data: any) => {
        const fbMessages = data.val()
        if (fbMessages) {
          const keys = Object.keys(fbMessages)
          archive.messages = keys.map((k: string) => {
            fbMessages[k].id = k
            return fbMessages[k]
          }).filter((m: Message) => m.type === 'image')
        }
        doIns()
      })
    }

    const doIns = () => {
      if (!inRef || !inRef.current) return
      inRef.current.child(event.id).once("value", (data: any) => {
        const inners = data.val()
        if (inners) {
          const keys = Object.keys(inners)
          archive.in = keys.map((k: string) => {
            inners[k].id = k
            return inners[k]
          })
        }
        doScore()
      })
    }

    const doScore = () => {
      if (!scoreRef || !scoreRef.current) return
      scoreRef.current.once("value", (data: any) => {
        const scores = data.val()
        if (scores) {
          const keys = Object.keys(scores)
          const myScoreKey: string[] | [] = keys.filter((k: string) => scores[k].eventRef === event.id)
          archive.score = myScoreKey.length > 0 ? scores[myScoreKey[0]] : null
        }
        saveArchive(archive)
        removeEvent(false)
      })
    }

    doEvent() // Callbacks chained
  }

  const removeEvent = (showSnack: boolean = true) => {

    const deleteEvent = () => {
      if (!eventsRef || !eventsRef.current) return
      eventsRef
        .current
        .child(event.id)
        .remove()
        .then(() => {
          if (showSnack) setSnackMsg('Event deleted.')
        })
        .catch((error: any) =>
          setSnackMsg(`Delete failed: ${error}`)
        )
    }

    const deleteWhosInList = () => {
      if (!inRef || !inRef.current) return
      inRef
        .current
        .child(event.id)
        .remove()
        .catch((error: any) =>
          console.log('WhosIn list deletion failed:', error),
        );
    };

    const deleteSeenCounts = () => {
      if (!seenCountRef || !seenCountRef.current) return
      seenCountRef
        .current
        .child(event.id)
        .remove()
        .catch((error: any) =>
          console.log('WhosIn list deletion failed:', error),
        );
    };

    const deleteMessages = () => {
      if (!msgRef || !msgRef.current) return
      msgRef
        .current
        .child(event.id)
        .remove()
        .catch((error: any) =>
          setSnackMsg(`Delete failed: ${error}`)
        )
    }

    deleteEvent()
    deleteMessages()
    deleteWhosInList()
    deleteSeenCounts()
  }

  useEffect(() => {
    archiveRef.current = firebase
      .getDatabase()
      .ref(cfg.root)
      .child(cfg.archiveRoot)

    eventsRef.current = firebase
      .getDatabase()
      .ref(cfg.root)
      .child(cfg.eventsRoot)

    msgRef.current = firebase
      .getDatabase()
      .ref(cfg.root)
      .child(cfg.messageRoot)

    seenCountRef.current = firebase
      .getDatabase()
      .ref(cfg.root)
      .child(cfg.seenRoot)

    inRef.current = firebase
      .getDatabase()
      .ref(cfg.root)
      .child(cfg.inRoot)

    scoreRef.current = firebase
      .getDatabase()
      .ref(cfg.root)
      .child(cfg.scoresRoot)
  }, [firebase, event.id]);

  return { archiveEvent }
}
