import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  MutableRefObject
} from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { AuthState, authState } from '../../common/utils'
import { SignIn } from '../signin'
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { Archive, AuthUserCtxProvider, User } from '../../model/interfaces'
import { useArchives } from '../../hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import { AuthUserContext } from '../../components/firebase'
import AlertDialog from '../../components/alertdialog'
import ArchiveCard from '../archives/archivecard'
import { formatDate } from '../../common/utils'
import { FirebaseContext } from '../../components/firebase'
import { ApppBar } from '../app'
import cfg from '../../model/db';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: 'darkGreen',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(5),
      },
    },
    tableContainer: {
      marginTop: '60px'
    },
  }),
);

export default function Archives() {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext)
  const authUser: AuthUserCtxProvider = useContext(AuthUserContext)
  const [archives, setArchives] = useState<Archive[] | null>(null)
  const [selectedArchiveCard, setSelectedArchiveCard] = useState<Archive | null>(null)
  const [showArchiveCard, setShowArchiveCard] = useState(false)
  const [alertDialogOpen, setAlertDialogOpen] = useState(false)
  const [snackMsg, setSnackMsg] = useState<string | null>(null);
  const archivesRef: MutableRefObject<any> = useRef()
  useArchives(setArchives)

  const selected = (archive: Archive) => {
    setSelectedArchiveCard(archive)
    setShowArchiveCard(true)
  }

  const confirmDelete = (archive: Archive) => {
    setSelectedArchiveCard(archive)
    setAlertDialogOpen(true)
  }

  const deleteArchive = () => {
    archivesRef
      .current
      .child(selectedArchiveCard?.id)
      .remove()
      .then(() => {
        setSnackMsg('Archive deleted.')
      })
      .catch((error: any) =>
        setSnackMsg(`Delete failed: ${error}`)
      )
  }

  const closeArchiveCard = () => {
    setShowArchiveCard(false)
  }

  useEffect(() => {
    archivesRef.current = firebase
      .getDatabase()
      .ref(`${cfg.root}/${cfg.archiveRoot}`)
  }, [firebase]);

  const InChip = (props: any) => {
    const { user } = props
    return (
      <div>
        <Tooltip title={`${user.firstName} ${user.lastName}`}>
          <Chip style={{ margin: '1px' }} variant="outlined" color='primary' label={user.initials} />
        </Tooltip>
      </div >
    )
  }

  const inners = (inners: User[]) => {
    if (!inners) return []
    return inners.map((i: User) => <InChip key={i.id} user={i} />)
  }

  return (
    <>
      <ApppBar title={'Archives'} setSnackMsg={setSnackMsg} />
      {authState(authUser.userInfo) === AuthState.NOT_SIGNED_IN && <SignIn />}
      {
        authState(authUser.userInfo) === AuthState.UNKNOWN && !archives && <div className={classes.root}>
          <CircularProgress />
        </div>
      }
      {
        authState(authUser.userInfo) === AuthState.SIGNED_IN && <React.Fragment>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Date Played</StyledTableCell>
                  <StyledTableCell align="left">Location</StyledTableCell>
                  <StyledTableCell align="left">Players</StyledTableCell>
                  <StyledTableCell align="left">Archive</StyledTableCell>
                  <StyledTableCell align="left">Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 500 } : {})}>
                <TableBody>
                  {archives && archives?.map((row: Archive) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell align="left">{formatDate(row.event.when)}</StyledTableCell>
                      <StyledTableCell align="left">{row.event.where}</StyledTableCell>
                      <StyledTableCell align="left">{inners(row.in)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <IconButton style={{ color: 'darkBlue' }} onClick={e => selected(row)}>
                          <ListAltTwoToneIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <IconButton style={{ color: 'red' }} onClick={e => confirmDelete(row)}>
                          <DeleteTwoToneIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Grow>
            </Table>
            {archives && archives.length === 0 && <h2> No archives</h2>}
          </TableContainer>
          <ArchiveCard showArchiveCard={showArchiveCard} closeArchiveCard={closeArchiveCard} archiveCard={selectedArchiveCard} />
        </React.Fragment>}
      <AlertDialog msg='Delete Archive?'
        alertDialogOpen={alertDialogOpen}
        setAlertDialogOpen={setAlertDialogOpen}
        doAction={deleteArchive} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!snackMsg}
        autoHideDuration={4000}
        onClose={() => setSnackMsg(null)}
        message={snackMsg} />
    </>
  )
}

