import React, { useState, useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import ForumIcon from '@material-ui/icons/Forum';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom"
import { EventCardContent, EventCardMenu } from './'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { formatAgo } from '../../../common/utils'
import { AuthUserContext } from '../../../components/firebase'
import Favicon from 'react-favicon';
import { useMessages, useSeenCount } from '../../../hooks'
import { Message, AuthUserCtxProvider } from '../../../model/interfaces'
import EventDialog from '../EventDialog'
import { MESSAGE_LIST } from '../../../common/routes'
import normal from '../../../assets/golf1.jpg'
import alert from '../../../assets/golf1alert.jpg'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    elevation: 24,
    variant: 'outlined',
  },
  button: {
    margin: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: 'white',
    subTitleStyle: 'white',
  },
  avatar: {
    backgroundColor: green[900],
    fontSize: 12,
  },
  cardContent: {
    flexGrow: 1,
  },
  editDiscussButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'spaceBetween',
    justifyContent: 'center',
  },
  iconText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'spaceBetween',
  },
}));

const EventCard = (props: any) => {
  const { event, canEdit, setSnackMsg } = props
  const classes = useStyles();
  const history = useHistory()
  const authUser: AuthUserCtxProvider = useContext(AuthUserContext)
  const [messages, setMessages] = useState<Message[]>([])
  const { getSeenCount } = useSeenCount(event, authUser.userInfo?.user?.guid)
  useMessages(event, setMessages, false)

  const showMessageList = () => {
    history.push({
      pathname: MESSAGE_LIST,
      state: { selectedEvent: event }
    })
  }

  const badgeContent = (): number => messages.length - getSeenCount()
  const showDot = (): 'dot' | 'standard' => messages.length - getSeenCount() < 0 ? 'dot' : 'standard'
  return (
    <>
      <Favicon url={badgeContent() === 0 ? normal : alert} />
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Tooltip title={`${event.createdBy.firstName} ${event.createdBy.lastName}`}>
              <Avatar aria-label="event" className={classes.avatar}>
                {event.createdBy.initials}
              </Avatar>
            </Tooltip>
          }
          action={
            <IconButton aria-label="delete-event">
              <EventCardMenu event={event} setSnackMsg={setSnackMsg} />
            </IconButton>
          }
          titleTypographyProps={{ variant: 'body1', color: 'primary' }}
          title={event.subject}
        />
        <EventCardContent {...props} />
        <div className={classes.editDiscussButtons}>
          {
            !canEdit && <EventDialog event={event} />
          }
          <Button
            onClick={showMessageList}
            color="primary"
            size="small"
            variant='outlined'
            className={classes.button}
            startIcon={<>
              <Badge badgeContent={badgeContent()} max={999}
                color="secondary"
                variant={showDot()}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}>
                <ForumIcon />
              </Badge>
            </>}>
            Discuss
      </Button>
        </div>
        <Typography color="textSecondary" align='center' variant="caption" component="h6">
          {`Created ${formatAgo(event.created)}`}
        </Typography>
      </Card >
    </>
  );
}
export default EventCard
