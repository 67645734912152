import React, { useContext } from 'react'
import { AuthUserContext } from '../../components/firebase'
import { EventList } from '../eventlist'
import { SignIn } from '../signin'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AuthUserCtxProvider } from '../../model/interfaces'
import { AuthState, authState } from '../../common/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(5),
      },
    },
  }),
);

const Landing = () => {
  const classes = useStyles()
  const authUser: AuthUserCtxProvider = useContext(AuthUserContext)

  return (
    <>
      {authState(authUser.userInfo) === AuthState.NOT_SIGNED_IN && <SignIn />}
      {authState(authUser.userInfo) === AuthState.UNKNOWN &&
        <div className={classes.root}>
          <CircularProgress />
        </div>}
      {authState(authUser.userInfo) === AuthState.SIGNED_IN && <EventList />}
    </>
  )
}
export default Landing
