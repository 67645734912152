import * as app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database'

const firebaseConfig = {
    apiKey: 'AIzaSyDC7WoEf8LGJFFEGgH4g9T_7vCLWr9UpCE',
    authDomain: 'whosin-624f8.firebaseapp.com',
    databaseURL: 'https://whosin-624f8.firebaseio.com',
    projectId: 'whosin-624f8',
    storageBucket: 'whosin-624f8.appspot.com',
    messagingSenderId: '279350115929',
};

class Firebase {
    auth: app.auth.Auth
    appDatabase: app.database.Database
    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth()
        this.appDatabase = app.database()
    }

    getDatabase = () => this.appDatabase

    createUserWithEmailAndPassword = (email: string, password: string) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    signInWithEmailAndPassword = (email: string, password: string) =>
        this.auth.signInWithEmailAndPassword(email, password);

    signOut = () => this.auth.signOut();

    resetPassword = (email: string) => this.auth.sendPasswordResetEmail(email);

    updatePassword = (password: string) =>
        this.auth.currentUser?.updatePassword(password);

    timestamp = () => app.database.ServerValue.TIMESTAMP // Firebase.database.ServerValue.TIMESTAMP
}
export default Firebase;
