import { useEffect, useRef, useContext, MutableRefObject } from 'react';
import { FirebaseContext } from '../components/firebase'
import { Archive } from '../model/interfaces'
import cfg from '../model/db';

export const useArchives = (setArchives: any) => {
  const archivesRef: MutableRefObject<any> = useRef()
  const firebase = useContext(FirebaseContext)

  useEffect(() => {
    archivesRef.current = firebase
      .getDatabase()
      .ref(cfg.root)
      .child(cfg.archiveRoot);

    const scoresListener = archivesRef.current.on('value', (snapshot: any) => {
      if (!snapshot ||
        typeof snapshot === 'undefined') {
        setArchives([])
        return;
      }

      const fbArchives: [Archive] = snapshot.val();
      if (fbArchives === null) {
        setArchives([])
        return
      }

      if (fbArchives) {
        const keys = Object.keys(fbArchives)
        setArchives(keys.map((key: string) => {
          let a = fbArchives[key as any]
          a.id = key
          return a
        }).reverse())
      }
    })
    return (() =>
      archivesRef.current ? archivesRef.current.off('value', scoresListener) : null)
  }, [firebase, setArchives])
}