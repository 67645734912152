import { useEffect, useRef, useContext, MutableRefObject } from 'react';
import { FirebaseContext } from '../components/firebase'
import { FbEvent, fbEventToEvent } from '../model/interfaces'
import cfg from '../model/db';

export const useEvents = (eventCount: Number, setEventCount: any, setEvents: any) => {
    const eventsRef: MutableRefObject<any> = useRef()
    const firebase = useContext(FirebaseContext)

    useEffect(() => {
        eventsRef.current = firebase
            .getDatabase()
            .ref(cfg.root)
            .child(cfg.eventsRoot);

        const eventListener = eventsRef.current.on('value', (snapshot: any) => {
            if (!snapshot ||
                typeof snapshot === 'undefined') {
                setEvents([])
                return;
            }

            const fbEvents: [FbEvent] = snapshot.val();
            if (fbEvents === null) {
                setEvents([])
                return
            }

            if (fbEvents) {
                const keys = Object.keys(fbEvents)
                const newEventCount = keys.length
                setEvents(keys.map(key => fbEventToEvent(fbEvents, key)).reverse())
                setEventCount(newEventCount)
            }
        })
        return (() =>
            eventsRef.current ? eventsRef.current.off('value', eventListener) : null)
    }, [firebase, eventCount, setEventCount, setEvents]);

}