import React, { useContext, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import { formatAgo, toColor, asUrl, uuid } from '../../../common/utils'
import { AuthUserContext } from '../../../components/firebase'
import { MessageCardMenu } from '.';
import { MessageImageView } from '.'
import { AuthUserCtxProvider } from '../../../model/interfaces'
import Linkify from 'react-linkify';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 345,
        },
        media: {
            height: 0,
            paddingTop: '45%', // C% = ( 100 / A ) * B, A = 20, B = 9
        },
        cardHeader: {
            padding: '2px',
        },
        cardActions: {
            padding: '0px',
        },
        cardContent: {
            padding: '2px',
            overflow: 'hidden',
            width: '90%',
        },
        panelSummary: {
            margin: '2px',
            content: '2px',
            paddingStart: '4px',
            paddingEnd: '4px',
            paddingBottom: '2px',
            paddingTop: '2px',
        },
        panelDetails: {
            margin: '2px',
            content: '2px',
            paddingStart: '4px',
            paddingEnd: '4px',
            paddingBottom: '2px',
            paddingTop: '2px',
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',

            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    }),
);

export default function MessageCard(props: any) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [zoomImg, setZoomImg] = useState(false)
    const auth: AuthUserCtxProvider = useContext(AuthUserContext)
    const { message, selectedEvent, setSnackMsg } = props
    message.text = message && message.text && message.text.trim()

    const handleExpandClick = (expanded: boolean) => {
        if (canExpand(message.text)) setExpanded(expanded);
    }

    const MAX_TEXT_LEN = 150
    const TRUNC_LINES = 3
    const isMyMessage = () => auth?.userInfo?.user?.guid! === message.guid
    const preserveNewLines = (text: string) => text && text.split('\n').map((para: string) =>
        <Typography key={uuid()}><Linkify>{para}</Linkify></Typography>)

    const occurrences = (text: string, subText: string, allowOverlapping: boolean = false) => {
        text += "";
        subText += "";
        if (subText.length <= 0) return (text.length + 1);

        let n = 0,
            pos = 0,
            step = allowOverlapping ? 1 : subText.length;

        while (true) {
            pos = text.indexOf(subText, pos);
            if (pos >= 0) {
                ++n;
                pos += step;
            } else break;
        }
        return n;
    }

    const canExpand = (text: string) => text &&
        (text.length > MAX_TEXT_LEN ||
            occurrences(text, '\n', false) >= TRUNC_LINES)

    const truncate = (text: string) => text && (text.substring(0, MAX_TEXT_LEN)
        .split('\n')
        .map((sub: string) => {
            return (<Typography key={uuid()}><Linkify>{sub}</Linkify></Typography> || [])
        }))

    const zoom = () => {
        setZoomImg(!zoomImg)
    }

    return (
        <Card className={classes.root} variant="elevation">
            <CardHeader className={classes.cardHeader}
                avatar={
                    <Avatar aria-label="message" style={{
                        backgroundColor: toColor(message.initials),
                        color: 'black',
                        fontSize: 12,
                    }}>
                        {message.initials}
                    </Avatar>
                }
                action={
                    <IconButton disabled={!isMyMessage()} aria-label="delete-message">
                        <MessageCardMenu
                            selectedEvent={selectedEvent}
                            message={message}
                            setSnackMsg={setSnackMsg} />
                    </IconButton>
                }
                title={message.subject}
                subheader={formatAgo(message.created)}
            />
            {message.type === 'image' && <CardMedia
                className={classes.media}
                component='div'
                onClick={zoom}
                image={asUrl(message.base64Img)}
                title={message.title}
            />}
            {message && message.text && <ExpansionPanel expanded={expanded}>
                {!expanded && <ExpansionPanelSummary className={classes.panelSummary}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    onClick={(e: any) => handleExpandClick(true)}
                >
                    <CardContent className={classes.cardContent}>
                        <Typography key={message.guid} variant="body2" color="textPrimary" component="span">
                            {truncate(message.text || '').slice(0, TRUNC_LINES)}{canExpand(message.text) ? ':' : ''}
                        </Typography>
                    </CardContent>
                </ExpansionPanelSummary>}
                {expanded && <ExpansionPanelDetails className={classes.panelDetails} onClick={(e: any) => handleExpandClick(false)}>
                    <CardContent className={classes.cardContent}>
                        <Typography key={message.guid} variant="body2" color="textPrimary" component="span">
                            {preserveNewLines(message.text)}
                        </Typography>
                    </CardContent>
                </ExpansionPanelDetails>}
            </ExpansionPanel>}
            {zoomImg && <MessageImageView imgUrl={asUrl(message.base64Img)} open={zoomImg} setOpen={setZoomImg} />}
        </Card >
    );
}