import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles((theme: any) => ({
  uploadCancelButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'spaceBetween',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
  },
}))

const ScoreCard = (props: any) => {
  const classes = useStyles()
  const { showScoreCard, closeScoreCard, scoreCard } = props

  if (!showScoreCard) return null
  return (
    <Dialog open={showScoreCard} onClose={closeScoreCard} >
      <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 500 } : {})}>
        <div style={{ margin: '10px' }}
          dangerouslySetInnerHTML={{
            __html: scoreCard || ''
          }}>
        </div>
      </Grow>
      <DialogActions>
        <div className={classes.uploadCancelButtons}>
          <Button
            onClick={e => closeScoreCard()}
            color="primary"
            variant="outlined"
            className={classes.button}
            startIcon={<CancelIcon />}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default ScoreCard