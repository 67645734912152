
import moment from 'moment';
import { UserInfo } from '../model/interfaces'
export const formatDate = (when: number) => moment(when).format('dddd, MMMM Do YYYY');
export const formatTime = (when: number) => moment(when).format('h:mma');
export const formatDateTime = (when: number) => moment(when).format('ddd, MMM Do@h:mma');
export const formatMsgTime = (when: number) => moment(when).format('M/D@hh:mm:ss');
export const formatAgo = (when: number) => moment(when).startOf('seconds').fromNow();
export const formatShortDate = (when: number) => moment(when).format('MMMM Do')

export enum AuthState {
  NOT_SIGNED_IN,
  UNKNOWN,
  SIGNED_IN
}
export const authState = (authUser: UserInfo | null | undefined): AuthState => {
  if (authUser?.authUser === null)
    return AuthState.NOT_SIGNED_IN
  else if (authUser?.authUser === undefined)
    return AuthState.UNKNOWN
  else
    return AuthState.SIGNED_IN
}

export function arrayBufferToString(buffer: any) {
  var byteArray = new Uint8Array(buffer);
  var str = "", cc = 0, numBytes = 0;
  for (var i = 0, len = byteArray.length; i < len; ++i) {
    var v = byteArray[i];
    if (numBytes > 0) {

      if ((cc & 192) === 192) {
        //processing tailing-bytes
        cc = (cc << 6) | (v & 63);
      } else {
        throw new Error("this is no tailing-byte");
      }
    } else if (v < 128) {
      //single-byte
      numBytes = 1;
      cc = v;
    } else if (v < 192) {
      //these are tailing-bytes
      throw new Error("invalid byte, this is a tailing-byte")
    } else if (v < 224) {
      //3 bits of header + 5bits of payload
      numBytes = 2;
      cc = v & 31;
    } else if (v < 240) {
      //4 bits of header + 4bit of payload
      numBytes = 3;
      cc = v & 15;
    } else {
      //UTF-8 theoretically supports up to 8 bytes containing up to 42bit of payload
      //but JS can only handle 16bit.
      throw new Error("invalid encoding, value out of range")
    }

    if (--numBytes === 0) {
      str += String.fromCharCode(cc);
    }
  }
  if (numBytes) {
    throw new Error("the bytes don't sum up");
  }
  return str;
}

export const clone = (obj: any) => {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

export const uuid = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
  return uuid;
}

export const toColor = (initials: string) => {
  if (!initials || initials.length !== 3) return 'rgb(255,25,57)';
  const r = Math.floor((3.14 * initials.charCodeAt(0)) & 0xff);
  const g = Math.floor((3.14 * initials.charCodeAt(1)) & 0xff);
  const b = Math.floor((3.14 * initials.charCodeAt(2)) & 0xff);
  return ['rgb(', r, ',', g, ',', b, ')'].join('');
};

function b64toBlob(dataURI: string) {
  if (!dataURI) return ''
  var byteString = atob(dataURI.split(',')[1]);
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/jpeg' })
}

export const asUrl = (b64Img: string) => b64Img ? (URL as any).createObjectURL(b64toBlob(b64Img)) : ''