import React from 'react'
import Resizer from 'react-image-file-resizer';
import { DropzoneDialog } from 'material-ui-dropzone'

const ImageUploadDialog = (props) => {
  const { uploadDialogOpen, setUploadDialogOpen, sendImage } = props

  const handleClose = () => {
    setUploadDialogOpen(false)
  }


  const handleSave = (files) => {
    files.forEach((file) => {
      Resizer.imageFileResizer(file, 500, 500, 'JPEG', 100, 0, (uri) => sendImage(uri), 'base64')
    })
    setUploadDialogOpen(false)
  }

  return (
    <>
      <DropzoneDialog
        open={uploadDialogOpen}
        onSave={handleSave}
        dialogTitle="Select Image"
        dropzoneText="Drag and drop here the image or click below"
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        showPreviews={true}
        filesLimit={1}
        submitButtonText="Send"
        maxFileSize={5000000}
        onClose={handleClose}
      />
    </>
  )
}
export default ImageUploadDialog